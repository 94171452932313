import { atom, useAtomValue, useSetAtom }                      from "jotai";
import { RESET, atomWithReset, atomWithStorage, useResetAtom } from "jotai/utils";

export const seekEnabled = atomWithReset ( false );
export const liveRef = atomWithReset ();
export const vodRef = atomWithReset ();

export const useGetSeekEnabled = () => {
	return useAtomValue ( seekEnabled );
};

export const useSetSeekEnabled = () => {
	return useSetAtom ( seekEnabled );
};

export const useGetLiveRef = () => {
	return useAtomValue ( liveRef );
};

export const useSetLiveRef = () => {
	return useSetAtom ( liveRef );
};

export const useGetVodRef = () => {
	return useAtomValue ( vodRef );
};

export const useSetVodRef = () => {
	return useSetAtom ( vodRef );
};

export const currProgress = atomWithReset ( 100 );
export const isPlayingAtom = atom ( false );
export const bufferPercent = atomWithReset ( 0 );
export const mouseDown = atomWithReset ( false );
export const timeSinceLive = atomWithReset ( 0 );
export const activePlayerType = atomWithReset ( "live" );
export const duration = atomWithReset ( 0 );
export const currentTime = atomWithReset ( 0 );

export const useGetIsPlaying = () => {
	return useAtomValue ( isPlayingAtom );
};

export const useSetIsPlaying = () => {
	return useSetAtom ( isPlayingAtom );
};
export const useGetCurrPogress = () => {
	return useAtomValue ( currProgress );
};

export const useSetCurrPogress = () => {
	return useSetAtom ( currProgress );
};

export const useGetBufferPercent = () => {
	return useAtomValue ( bufferPercent );
};

export const useSetBufferPercent = () => {
	return useSetAtom ( bufferPercent );
};

export const useGetIsMouseDown = () => {
	return useAtomValue ( mouseDown );
};

export const useSetIsMouseDown = () => {
	return useSetAtom ( mouseDown );
};

export const useGetTimeSinceLive = () => {
	return useAtomValue ( timeSinceLive );
};

export const useSetTimeSinceLive = () => {
	return useSetAtom ( timeSinceLive );
};

export const useGetActivePlayerType = () => {
	return useAtomValue ( activePlayerType );
};

export const useSetActivePlayerType = () => {
	return useSetAtom ( activePlayerType );
};

export const hasError = atomWithReset ( false );
export const isMute = atomWithReset ( false );
export const isPaused = atomWithReset ( undefined );
export const fullscreen = atomWithReset ( false );
export const qualities = atomWithReset ( [] );
export const currQuality = atomWithReset ( "auto" );
export const volumePercentLocal = atomWithReset ();
export const volumePercent = atomWithStorage ( "volumePercent", 70 );
export const currPlaybackSpeed = atomWithReset ( "normal" );

export const useGetHasError = () => {
	return useAtomValue ( hasError );
};

export const useSetHasError = () => {
	return useSetAtom ( hasError );
};

export const useGetIsMute = () => {
	return useAtomValue ( isMute );
};

export const useSetIsMute = () => {
	return useSetAtom ( isMute );
};

export const useGetIsPaused = () => {
	return useAtomValue ( isPaused );
};

export const useSetIsPaused = () => {
	return useSetAtom ( isPaused );
};

export const useGetFullscreen = () => {
	return useAtomValue ( fullscreen );
};

export const useSetFullscreen = () => {
	return useSetAtom ( fullscreen );
};

export const useGetQualities = () => {
	return useAtomValue ( qualities );
};

export const useSetQualities = () => {
	return useSetAtom ( qualities );
};

export const useGetCurrQuality = () => {
	return useAtomValue ( currQuality );
};

export const useSetCurrQuality = () => {
	return useSetAtom ( currQuality );
};

export const useGetVolPercentLocal = () => {
	return useAtomValue ( volumePercentLocal );
};

export const useSetVolPercentLocal = () => {
	return useSetAtom ( volumePercentLocal );
};

export const useGetVolPercent = () => {
	return useAtomValue ( volumePercent );
};

export const useSetVolPercent = () => {
	return useSetAtom ( volumePercent );
};

export const useGetCurrPlaybackSpeed = () => {
	return useAtomValue ( currPlaybackSpeed );
};

export const useSetCurrPlaybackSpeed = () => {
	return useSetAtom ( currPlaybackSpeed );
};

export const useGetDuration = () => {
	return useAtomValue ( duration );
};

export const useSetDuration = () => {
	return useSetAtom ( duration );
};

export const useGetCurrentTime = () => {
	return useAtomValue ( currentTime );
};

export const useSetCurrentTime = () => {
	return useSetAtom ( currentTime );
};

export const useResetAtoms = () => {
	const seekEnabledReset   = useResetAtom ( seekEnabled );
	const liveRefReset       = useResetAtom ( liveRef );
	const vodRefReset        = useResetAtom ( vodRef );
	const currProgressReset  = useResetAtom ( currProgress );
	const bufferPercentReset = useResetAtom ( bufferPercent );
	const mouseDownReset     = useResetAtom ( mouseDown );
	const timeSinceLiveReset = useResetAtom ( timeSinceLive );
	// const hasErrorReset           = useResetAtom ( hasError );
	// const isMuteReset             = useResetAtom ( isMute );
	const isPausedReset    = useResetAtom ( isPaused );
	const fullscreenReset  = useResetAtom ( fullscreen );
	const qualitiesReset   = useResetAtom ( qualities );
	const currQualityReset = useResetAtom ( currQuality );
	// const volumePercentLocalReset = useResetAtom ( volumePercentLocal );
	const currPlaybackSpeedReset = useResetAtom ( currPlaybackSpeed );

	return () => {
		// Call all reset funcitons here
		seekEnabledReset ( RESET );
		liveRefReset ( RESET );
		vodRefReset ( RESET );
		currProgressReset ( RESET );
		bufferPercentReset ( RESET );
		mouseDownReset ( RESET );
		timeSinceLiveReset ( RESET );
		// hasErrorReset ( RESET );
		// isMuteReset ( RESET );
		isPausedReset ( RESET );
		fullscreenReset ( RESET );
		qualitiesReset ( RESET );
		currQualityReset ( RESET );
		// volumePercentLocalReset ( RESET );
		currPlaybackSpeedReset ( RESET );
	};
};
